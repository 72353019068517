
import { defineComponent } from "vue";
import type { PropType } from "vue";

export default defineComponent({
  name: "StartTest",
  props: {
    product: { type: String as PropType<"nts" | "ntd">, required: true },
    requiresLocking: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
});
