
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Store, apiClient } from "@/store";
import type { State } from "@/store/internal/state";
import CardDialog from "@/components/CardDialog.vue";
import Checkbox from "@/components/Checkbox.vue";
import PanelCapnogram from "./detail/PanelCapnogram.vue";
import IconBlank from "@/assets/icons/IconBlank.vue";
import IconQuestion from "@/assets/icons/IconQuestion.vue";
import Tooltip from "@/components/Tooltip.vue";
import { DeviceDataFetcher } from "@/store/fetcher";
import { ChartCapnogramWithMetadata } from "@/store/fetcher/capnogram";
import { DeviceAdditionalInfo } from "@/store/additionalInfo";
import CardDialogBackground from "@/components/CardDialogBackground.vue";
import { longHover } from "@/components/mixins/longHover";
import { ACTION_FETCH_USER_DATA } from "@/store/constants";
import { APIUserGroup } from "@/store/types";

/**
 * Dialog for the final part of device allocation: shows the user the instructions for use if required and watches for
 * new capnograms on the selected UDI, using a [[DeviceDataFetcher]], displaying them if and when they arrive. The user
 * must then check one of the checkboxes to confirm the breath record is correct or they were unable to do one.
 *
 * ![](media://dialogparticipanttraining.png)
 */
@Component({
  name: "dialog-participant-training",
  components: {
    CardDialogBackground,
    Tooltip,
    IconQuestion,
    IconBlank,
    PanelCapnogram,
    Checkbox,
    CardDialog,
  },
  mixins: [longHover("Quality")],
})
export default class DialogParticipantTraining extends Vue {
  deviceDataFetcher?: DeviceDataFetcher;
  // data from previous dialog, passed via props from ViewDashboard
  /**
   * UDI of handset selected in previous [[DialogDeviceAllocation]] dialog. Stored in [[ViewDashboard]].
   * @category Vue Prop
   */
  // TODO: CAM-563 Why does this allow null as a value?
  // https://camresp.atlassian.net/browse/CAM-563
  @Prop({ type: Object }) readonly udi!: { value: string; name: string } | null;
  /**
   * Group selected in previous [[DialogDeviceAllocation]] dialog. Stored in [[ViewDashboard]].
   * @category Vue Prop
   */
  @Prop({ type: Object }) readonly group!: APIUserGroup | null;
  /**
   * DUID entered in previous [[DialogDeviceAllocation]] dialog. Stored in [[ViewDashboard]].
   * @category Vue Prop
   */
  @Prop({ type: String, default: "" }) readonly duid!: string;
  /**
   * Additional info entered in previous [[DialogDeviceAllocation]] dialog. Stored in [[ViewDashboard]].
   * @category Vue Prop
   */
  @Prop({ type: Object }) readonly additionalInfo!: DeviceAdditionalInfo;

  /**
   * Whether the user has confirmed the user's training
   * @category Vue Data
   */
  confirmed: boolean = false;
  /**
   * Whether the user cannot take a breath record
   * @category Vue Data
   */
  unableToRecord: boolean = false;
  /**
   * Whether we're showing the instructions for use dialog
   * @category Vue Data
   */
  showingInstructions: boolean = false;
  /**
   * Data object containing the series and scales for the latest capnogram
   * @category Vue Data
   */
  latestCapnogram: ChartCapnogramWithMetadata | null = null;
  /**
   * Formatted date of the start of this capnogram. See [[formatDate]].
   * @category Vue Data
   */
  latestCapnogramStartTime: string | null = null;
  /**
   * Progress percent for new data
   * @category Vue Data
   */
  progress: number | null = null;

  /**
   * Checks whether the breath quality of the latest capnogram is above the required threshold (75%).
   * @returns `true` if the latest breath record received had a quality greater than or equal to 75%
   * @category Vue Computed
   */
  get qualityIsOk(): boolean {
    return (
      this.latestCapnogram !== null &&
      this.latestCapnogram.chartCapnogram.quality !== null &&
      this.latestCapnogram.chartCapnogram.quality >= 75
    );
  }

  /**
   * Whether the complete button is disabled. Requires one of the checkboxes to be checked.
   * @returns `true` if any of the checkboxes are checked just above the complete button
   * @category Vue Computed
   */
  get completeDisabled() {
    return (
      // uncomment this to require a capnogram before continuing
      !this.confirmed && !this.unableToRecord /*!this.latestCapnogram ||*/
    );
  }

  // noinspection JSUnusedGlobalSymbols
  /**
   * Creates a [[DeviceDataFetcher]] object that listens on a Pusher channel for new capnograms associated with the
   * allocated UDI. Won't bother checking for existing data. This is disposed of in [[beforeDestroy]].
   * @category Vue Lifecycle
   */
  created() {
    this.deviceDataFetcher = new DeviceDataFetcher({
      client: apiClient,
      pusher: (this.$store as Store<State>).pusher!,
      udi: this.udi ? this.udi.value : undefined,
      onData: (deviceData) => {
        // TODO: may want to filter on DUID here to?
        if (deviceData !== null) {
          // store the capnogram, ignoring the rest of the data
          this.latestCapnogramStartTime = deviceData.deviceCapnogramStartTime;
          this.latestCapnogram = deviceData.deviceCapnogram;

          // TODO: uncomment once we've worked out what's going on with quality
          // if (this.qualityIsOk) {
          // this.confirmed = true;
          // }

          // update the new DUID in the list with the new data
          // (fetching ALL user data here is a bit excessive, but...)
          this.$store.dispatch(ACTION_FETCH_USER_DATA);
        }
      },
      onProgress: (progress) => (this.progress = progress),
      // only request new data, not old records
      newDataOnly: true,
    });
  }

  // noinspection JSUnusedGlobalSymbols
  /**
   * Disposes of the [[DeviceDataFetcher]] created in [[created]], unsubcribing from the Pusher channel.
   * @category Vue Lifecycle
   */
  beforeDestroy() {
    // stop the data fetcher if there is one
    if (this.deviceDataFetcher) {
      this.deviceDataFetcher.dispose();
      delete this.deviceDataFetcher;
    }
  }
}
