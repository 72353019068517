import type { Route, RouteConfig } from "vue-router";

import PanelPrivacy from "@/views/panels/dashboard/privacy/PanelPrivacy.vue";
import PanelDocumentation from "@/views/panels/dashboard/documentation/PanelDocumentation.vue";
import PanelList from "@/views/panels/dashboard/list/PanelList.vue";
import PanelDetail from "@/views/panels/dashboard/detail/PanelDetail.vue";
import ViewDashboard from "@/views/common/ViewDashboard.vue";

// Ntc routes
const route: RouteConfig = {
  path: "/",
  component: ViewDashboard,
  meta: { requiresRwDash: true, selectedProduct: "ntc" },
  children: [
    {
      path: "detail",
      name: "dashboard",
      component: PanelDetail,
      meta: {
        requiresLogin: true,
        requiresDetailPermission: true,
        order: 1,
        title: () => "",
      },
    },
    {
      path: "detail/:duid",
      name: "dashboard-with-duid",
      component: PanelDetail,
      meta: {
        requiresLogin: true,
        requiresDetailPermission: true,
        order: 1,
        title: (route: Route) => route.params.duid,
      },
    },
    {
      path: "list",
      name: "list",
      component: PanelList,
      meta: {
        requiresLogin: true,
        order: 1,
        title: () => "List",
      },
    },
    {
      path: "documentation",
      name: "documentation",
      component: PanelDocumentation,
      meta: {
        requiresLogin: true,
        order: 1,
        title: () => "Documentation",
      },
    },
    {
      path: "privacy",
      name: "privacy",
      component: PanelPrivacy,
      meta: {
        requiresLogin: true,
        order: 1,
        title: () => "Privacy Policy",
      },
    },
  ],
};

export default route;
