
import { defineComponent } from "vue";
import { formattedResultsMixin } from "@/components/mixins/formattedResultsMixin";
import {
  ntsPrefix,
  ActionTypes,
  GetterTypes,
} from "@/store/internal/modules/nts";
import PanelHeader from "@/views/common/PanelHeader.vue";
import Sidebar from "@/views/common/Sidebar.vue";
import { HandsetV2 } from "@/store/types";

export default defineComponent({
  components: { PanelHeader, Sidebar },
  mixins: [formattedResultsMixin],
  data() {
    return { loading: true };
  },
  computed: {
    capnogramId(): string {
      return this.$route.params.capnogramId;
    },
    handset(): HandsetV2 | null {
      return this.$store.getters[
        ntsPrefix(GetterTypes.GET_HANDSET_BY_CAPNOGRAM_ID)
      ](this.capnogramId);
    },
  },
  mounted() {
    // Purposefully being explicit
    this.loading = true;

    const promises = Promise.all([
      this.$store.dispatch(
        ntsPrefix(ActionTypes.GET_CAPNOGRAM_RAW),
        this.$route.params.capnogramId
      ),
      this.$store.dispatch(
        ntsPrefix(ActionTypes.GET_CAPNOGRAM_COMPUTED_FEATURES),
        this.$route.params.capnogramId
      ),
      this.$store.dispatch(
        ntsPrefix(ActionTypes.GET_CAPNOGRAM_DIAGNOSTIC_RESULT),
        this.$route.params.capnogramId
      ),
      this.$store.dispatch(ntsPrefix(ActionTypes.GET_HANDSETS), "nts"),
    ])
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
        this.$nextTick(() => {
          window.print();
          this.$router.go(-1);
        });
      });
  },
});
