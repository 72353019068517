
import { GetterTypes, ntsPrefix } from "@/store/internal/modules/nts";
import { defineComponent } from "vue";
import { ChartAverageWaveform } from "@/store/fetcher/waveform";
import PanelWaveform from "@/views/panels/dashboard/detail/PanelWaveform.vue";

export default defineComponent({
  components: { PanelWaveform },
  props: {
    capnogramId: {
      type: String,
      required: true,
    },
  },
  computed: {
    averageWaveform(): ChartAverageWaveform | null {
      const waveform: ChartAverageWaveform | null = this.$store.getters[
        ntsPrefix(GetterTypes.GET_STANDARDISED_WAVEFORM_CHART_BY_CAPNOGRAM_ID)
      ](this.capnogramId);
      if (!waveform) return null;
      waveform.series[waveform.series.length - 1].lineColour = "black";
      return waveform;
    },

    capnogramStartTime(): string | null {
      return this.$store.getters[
        ntsPrefix(GetterTypes.GET_CAPNOGRAM_START_TIME_BY_ID)
      ](this.capnogramId);
    },
    healthyWaveform(): ChartAverageWaveform | null {
      return this.$store.getters[
        ntsPrefix(GetterTypes.GET_HEALTHY_WAVEFORM_CHART_BY_CAPNOGRAM_ID)
      ](this.capnogramId);
    },
  },
});
