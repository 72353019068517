/**
 * This module exposes the configuration of the application. This module gets
 * constants from environment variables and cast them to appropriate types.
 */

interface GetArgs<T> {
  name: string;
  fallback?: T;
}

interface GetVarArgs<T> extends GetArgs<T> {
  coerce: (s: string) => T;
}

/**
 * Gets an environment variable and uses a passed in function 'coerce' to cast
 * the returned value. If the environment variable is not defined then returns
 * the fallback value.
 *
 * If both the environment variable and the fallback value are not defined
 * throws an Error.
 *
 * @param args -
 *  name - name of the environment variable
 *  fallback - default value used if env var is not defined
 *  coerce - callback function to cast the env var into the desired type
 * @returns environment variable in the desired type.
 */
const getVar = <T>({ name, fallback, coerce }: GetVarArgs<T>) => {
  const envVar = process.env[name];

  if (envVar === undefined) {
    if (fallback === undefined)
      throw Error(`${name} is undefined and no fallback value given`);
    return fallback;
  }

  return coerce(envVar);
};

/**
 * Gets a boolean environment variable.
 * @param args -
 *  name - name of the environment variable
 *  fallback - default value used if env var is not defined
 * @returns environment variable as a boolean
 */
const getBool = (args: GetArgs<boolean>) => {
  return getVar({ ...args, coerce: stringToBoolean });
};

/**
 * Gets a string environment variable.
 * @param args -
 *  name - name of the environment variable
 *  fallback - default value used if env var is not defined
 * @returns environment variable as a string
 */
const getString = (args: GetArgs<string>) => {
  return getVar({ ...args, coerce: (val) => val });
};

/**
 * Gets an array of strings from an environment variable. This assumes the
 * string is split with commas.
 * @param args -
 *  name - name of the environment variable
 *  fallback - default value used if env var is not defined
 * @returns environment variable parsed as an array of strings
 */
const getStringArray = (args: GetArgs<string[]>) => {
  return getVar({
    ...args,
    coerce: (val) => val.split(",").map((s) => s.trim()),
  });
};

/**
 * Gets a string environment variable.
 * @param args -
 *  name - name of the environment variable
 *  fallback - default value used if env var is not defined
 * @returns environment variable as a string
 */
const getNumber = (args: GetArgs<number>) => {
  const val = getVar({ ...args, coerce: (val) => Number(val) });

  if (isNaN(val)) throw Error(`${args.name} is and cannot be NaN`);

  return val;
};

/**
 * Converts string to boolean value.
 *
 * Throws an error in the string does not match the one of the expected values.
 *
 * Expected value are case insensitive and include 'true', 'false', 'yes', 'no',
 * '1', '0', 'on' and 'off'.
 *
 * @param s - string to convert to a boolean
 * @returns boolean representation of the string
 */
const stringToBoolean = (s: string): boolean => {
  const s_tidy = s.toLowerCase().trim();

  if (
    s_tidy === "true" ||
    s_tidy === "yes" ||
    s_tidy === "1" ||
    s_tidy === "on"
  )
    return true;

  if (
    s_tidy === "false" ||
    s_tidy === "no" ||
    s_tidy === "0" ||
    s_tidy === "off"
  )
    return false;

  throw Error(
    "Unexpected string. Expected values include 'true', 'false', 'yes', 'no', '1', '0', 'on' and 'off'."
  );
};

// Optional environment variables

/** Enable Pusher to log to the console for debugging. */
const ENABLE_PUSHER_LOG_TO_CONSOLE = getBool({
  name: "VUE_APP_ENABLE_PUSHER_LOG_TO_CONSOLE",
  fallback: false,
});

/** Enable Sentry Error and Event reporting. */
const ENABLE_SENTRY = getBool({
  name: "VUE_APP_ENABLE_SENTRY",
  fallback: true,
});

/** Enable CSS transitions across the application. */
const ENABLE_TRANSITIONS = getBool({
  name: "VUE_APP_ENABLE_TRANSITIONS",
  fallback: true,
});

/** DSN of the Sentry project for the dashboard. */
const SENTRY_DSN = getString({
  name: "VUE_APP_SENTRY_DSN",
  fallback:
    "https://fff5ba4641ec442ab9d74fb53477bbe6@o511836.ingest.sentry.io/5652332",
});

/** Length of time in minutes after which an inactive user is logged out.
 *
 * Timeout functionality is disabled when value equals zero.
 */
const USER_IDLE_TIMEOUT_DURATION = getNumber({
  name: "VUE_APP_USER_IDLE_TIMEOUT_DURATION",
  fallback: 15,
});

// Required env vars

/** Route of the target API without a trailing slash */
const API_BASE_URL = getString({ name: "VUE_APP_API_BASE_URL" });

/**
 * Statistics displayed on the trends graph and the summary statistics on the
 * details page
 */
const DISPLAYED_STATISTICS = getStringArray({
  name: "VUE_APP_DISPLAYED_STATISTICS",
});

/** Enable the RAG statuses on the detail page. This includes the summary
 * statistics card and the trends graph */
const ENABLE_RAG_STATUS = getBool({ name: "VUE_APP_ENABLE_RAG_STATUS" });

/**
 * Used for Sentry configuration and displayed in the EnvironmentBanner.
 * Expected values are local|development|test|staging|production.
 */
const ENV_NAME = getString({ name: "VUE_APP_ENV_NAME" });

/**
 * Contents of the footer. If this is an empty string no footer warning is
 * displayed.
 */
const FOOTER_WARNING = getString({ name: "VUE_APP_FOOTER_WARNING" });

/** Git commit SHA */
const GIT_SHA = getString({ name: "VUE_APP_GIT_SHA" });

/** URL of Internal Portal without a trailing slash */
const INTERNAL_PORTAL_URL = getString({ name: "VUE_APP_INTERNAL_PORTAL_URL" });

/** URL prefix for the dashboard asset bucket */
const DASHBOARD_ASSET_BUCKET_PREFIX = getString({
  name: "VUE_APP_DASHBOARD_ASSET_BUCKET_PREFIX",
});

/** Key to the Pusher application */
const PUSHER_KEY = getString({ name: "VUE_APP_PUSHER_KEY" });

/**
 * Version of the application displayed in the footer and used when reporting
 * errors.
 */
const VERSION = require("../package.json").version;

const NTC_DASHBOARD_IFU_FILE = getString({
  name: "VUE_APP_NTC_DASHBOARD_IFU_FILE",
  fallback: "NTC_Dashboard_IFU.pdf",
});
const NTC_PRODUCT_LABEL_FILE = getString({
  name: "VUE_APP_NTC_PRODUCT_LABEL_FILE",
  fallback: "NTC_Product_Label.png",
});

const NTS_PRODUCT_LABEL_FILE = getString({
  name: "VUE_APP_NTS_PRODUCT_LABEL_FILE",
  fallback: "NTS_Product_Label.pdf",
});
const NTS_PRODUCT_LABEL_IMAGE = getString({
  name: "VUE_APP_NTS_PRODUCT_LABEL_IMAGE",
  fallback: "NTS_Product_Label.png",
});
const NTS_DASHBOARD_IFU_FILE = getString({
  name: "VUE_APP_NTS_DASHBOARD_IFU_FILE",
  fallback: "NTS_Dashboard_IFU.pdf",
});
const NTS_DASHBOARD_IFU_IMAGE = getString({
  name: "VUE_APP_NTS_DASHBOARD_IFU_IMAGE",
  fallback: "NTS_Dashboard_IFU.png",
});
const NTS_ACCEPTED_CAPNOGRAM_WINDOW_MINS = getNumber({
  name: "VUE_APP_NTS_ACCEPTED_CAPNOGRAM_WINDOW_MINS",
  fallback: 10,
});
const NTS_LOAD_TEST_TIMEOUT_MINS = getNumber({
  name: "VUE_APP_NTS_LOAD_TEST_TIMEOUT_MINS",
  fallback: 7.5,
});
const NTS_TEST_FAILURE_TIMEOUT_SECS = getNumber({
  name: "VUE_APP_NTS_TEST_FAILURE_TIMEOUT_SECS",
  fallback: 30,
});

const NTD_PRODUCT_LABEL_FILE = getString({
  name: "VUE_APP_NTD_PRODUCT_LABEL_FILE",
  fallback: "NTD_Product_Label_Rev1.0.png",
});
const NTD_PRODUCT_LABEL_IMAGE = getString({
  name: "VUE_APP_NTD_PRODUCT_LABEL_IMAGE",
  fallback: "NTD_Product_Label_Rev1.0.png",
});
const NTD_DASHBOARD_IFU_FILE = getString({
  name: "VUE_APP_NTD_DASHBOARD_IFU_FILE",
  fallback: "NTD_Dashboard_IFU_Rev1.0.pdf",
});
const NTD_DASHBOARD_IFU_IMAGE = getString({
  name: "VUE_APP_NTD_DASHBOARD_IFU_IMAGE",
  fallback: "NTD_Dashboard_IFU.png",
});
const NTD_TRAINING_VIDEO = getString({
  name: "VUE_APP_NTD_TRAINING_VIDEO",
  fallback: "NTD_Training_Video.mp4",
});

const NTH_HANDSET_IFU_FILE = getString({
  name: "VUE_APP_NTH_HANDSET_IFU_FILE",
  fallback: "NTH_IFU_Rev9.0.pdf",
});
const NTH_HANDSET_IFU_IMAGE = getString({
  name: "VUE_APP_NTH_HANDSET_IFU_IMAGE",
  fallback: "NTH_Handset_IFU.png",
});
const NTH_HANDSET_QSG_FILE = getString({
  name: "VUE_APP_NTH_HANDSET_QSG_FILE",
  fallback: "NTH_QSG_Rev5.0.pdf",
});

const GENERIC_TEST_FAILURE_MESSAGE = "Test failed. Please try again.";

// Midnight Browser Time
const getResultCutOffTime = () => new Date(new Date().toDateString());

export {
  API_BASE_URL,
  DISPLAYED_STATISTICS,
  ENABLE_PUSHER_LOG_TO_CONSOLE,
  ENABLE_RAG_STATUS,
  ENABLE_SENTRY,
  ENABLE_TRANSITIONS,
  ENV_NAME,
  FOOTER_WARNING,
  GENERIC_TEST_FAILURE_MESSAGE,
  GIT_SHA,
  INTERNAL_PORTAL_URL,
  NTC_DASHBOARD_IFU_FILE,
  NTC_PRODUCT_LABEL_FILE,
  NTS_PRODUCT_LABEL_FILE,
  NTS_PRODUCT_LABEL_IMAGE,
  NTS_DASHBOARD_IFU_FILE,
  NTS_DASHBOARD_IFU_IMAGE,
  NTD_PRODUCT_LABEL_FILE,
  NTD_PRODUCT_LABEL_IMAGE,
  NTD_DASHBOARD_IFU_FILE,
  NTD_DASHBOARD_IFU_IMAGE,
  NTD_TRAINING_VIDEO,
  DASHBOARD_ASSET_BUCKET_PREFIX,
  NTS_ACCEPTED_CAPNOGRAM_WINDOW_MINS,
  NTS_LOAD_TEST_TIMEOUT_MINS,
  NTS_TEST_FAILURE_TIMEOUT_SECS,
  NTH_HANDSET_IFU_FILE,
  NTH_HANDSET_IFU_IMAGE,
  NTH_HANDSET_QSG_FILE,
  PUSHER_KEY,
  SENTRY_DSN,
  USER_IDLE_TIMEOUT_DURATION,
  VERSION,
  getResultCutOffTime,
};
