import type { Route, RouteConfig } from "vue-router";

import ViewLoginWrapper from "@/views/ViewLoginWrapper.vue";
import PanelLogin from "@/views/panels/login/PanelLogin.vue";
import PanelRegister from "@/views/panels/login/PanelRegister.vue";
import PanelForgottenPassword from "@/views/panels/login/PanelForgottenPassword.vue";
import PanelPasswordReset from "@/views/panels/login/PanelPasswordReset.vue";

// Routes used before user has logged in
const route: RouteConfig = {
  path: "/login",
  component: ViewLoginWrapper,
  children: [
    {
      path: "",
      name: "login",
      component: PanelLogin,
      meta: {
        order: 0,
        title: () => "Login",
      },
    },
    {
      path: "register",
      name: "register",
      component: PanelRegister,
      meta: {
        order: 0,
        title: () => "Register",
      },
    },
    {
      path: "forgotten",
      name: "forgotten-password",
      component: PanelForgottenPassword,
      meta: {
        order: 0,
        title: () => "Forgotten Password",
      },
    },
    {
      path: "reset",
      name: "reset-password",
      component: PanelPasswordReset,
      meta: {
        order: 0,
        title: (route: Route) =>
          route.query.set ? "Set Password" : "Reset Password",
      },
    },
  ],
};

export default route;
