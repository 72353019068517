
import { defineComponent, PropType } from "vue";
import { formatNtdResultsMixin } from "../mixins/formatNtdResultsMixin";
import { EnrichedCapnogram } from "@/store/internal/modules/nts";

export default defineComponent({
  name: "NtdTestInput",
  mixins: [formatNtdResultsMixin],
  props: {
    capnogram: {
      type: Object as PropType<EnrichedCapnogram | null>,
      required: true,
    },
  },
});
