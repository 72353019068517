
import { defineComponent } from "vue";
import { formattedResultsMixin } from "@/components/mixins/formattedResultsMixin";
import { formatNtdResultsMixin } from "@/components/mixins/formatNtdResultsMixin";
import {
  ntsPrefix,
  ActionTypes,
  GetterTypes,
} from "@/store/internal/modules/nts";
import NtdWaveform from "@/components/diagnosis/NtdWaveform.vue";
import PanelHeader from "@/views/common/PanelHeader.vue";
import Sidebar from "@/views/common/Sidebar.vue";
import { HandsetV2 } from "@/store/types";
import PanelCapnogram from "@/views/panels/dashboard/detail/PanelCapnogram.vue";
import { VERSION } from "@/config";


export default defineComponent({
  name: "NtdPrintPage",
  components: { NtdWaveform, PanelCapnogram, PanelHeader, Sidebar },
  mixins: [formattedResultsMixin, formatNtdResultsMixin],
  data() {
    return { loading: true, version: VERSION };
  },
  computed: {
    capnogramId(): string {
      return this.$route.params.capnogramId;
    },
    handset(): HandsetV2 | null {
      return this.$store.getters[
        ntsPrefix(GetterTypes.GET_HANDSET_BY_CAPNOGRAM_ID)
      ](this.capnogramId);
    },
  },
  mounted() {
    // Purposefully being explicit
    this.loading = true;

    const promises = Promise.all([
      this.$store.dispatch(
        ntsPrefix(ActionTypes.GET_CAPNOGRAM_RAW),
        this.$route.params.capnogramId
      ),
      this.$store.dispatch(
        ntsPrefix(ActionTypes.GET_CAPNOGRAM_COMPUTED_FEATURES),
        this.$route.params.capnogramId
      ),
      this.$store.dispatch(
        ntsPrefix(ActionTypes.GET_CAPNOGRAM_DIAGNOSTIC_RESULT),
        this.$route.params.capnogramId
      ),
      this.$store.dispatch(ntsPrefix(ActionTypes.GET_HANDSETS), "ntd"),
    ])
      .then(() => {
        // requires capnogram to be in store hence fetch afterwards
        this.$store.dispatch(
          ntsPrefix(ActionTypes.GET_HEALTHY_WAVEFORM_BY_CAPNOGRAM_ID),
          this.$route.params.capnogramId
        )
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
        this.$nextTick(() => {
          // delay to allow the graphs to render prior to print window
          setTimeout(() => {
            window.print();
            this.$router.go(-1);
          }, 500);
        })
      });
  },
});
