import type { RouteConfig } from "vue-router";

import ViewDashboard from "@/views/common/ViewDashboard.vue";
import ProductSelection from "@/views/common/ProductSelection.vue";

// Route for selecting the product to be used. Internal facing, not used by
// consumers.
const route: RouteConfig = {
  path: "/product",
  component: ViewDashboard,
  meta: { requiresLogin: true },
  children: [
    {
      path: "",
      component: ProductSelection,
      name: "product",
      meta: { title: () => "Product Selection", order: 1 },
    },
  ],
};

export default route;
