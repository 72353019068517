import { render, staticRenderFns } from "./DialogParticipantTraining.vue?vue&type=template&id=306a5144&"
import script from "./DialogParticipantTraining.vue?vue&type=script&lang=ts&"
export * from "./DialogParticipantTraining.vue?vue&type=script&lang=ts&"
import style0 from "./DialogParticipantTraining.vue?vue&type=style&index=0&id=306a5144&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports