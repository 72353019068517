import { render, staticRenderFns } from "./FailedBreathRecord.vue?vue&type=template&id=13f7870b&scoped=true&"
import script from "./FailedBreathRecord.vue?vue&type=script&lang=ts&"
export * from "./FailedBreathRecord.vue?vue&type=script&lang=ts&"
import style0 from "./FailedBreathRecord.vue?vue&type=style&index=0&id=13f7870b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13f7870b",
  null
  
)

export default component.exports