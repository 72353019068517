import { DeviceAdditionalInfo } from "@/store/additionalInfo";

/**
 * Enum for the different types of errors/messages that the app can display. Used to determine which icon to display
 * in the [[CardDialog]] and what the dismiss button should do. See [[App]] for where these are displayed.
 */
export enum ErrorLevel {
  /**
   * For notes, uses a blue information icon and "Back" button
   *
   * ![](media://errorinfo.png)
   */
  INFO,
  /**
   * For recoverable errors, uses a red cross icon and "Back" button
   *
   * ![](media://error.png)
   */
  ERROR,
  /**
   * For non-recoverable errors, uses red cross icon and "Reload" button that reloads the entire page when clicked
   *
   * ![](media://errorfatal.png)
   */
  FATAL,
}

/** Regular expression for matching emails. Matches that same language as `<input type="email">`. */
export const EMAIL_REGEXP =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

/** Response body of `POST /user/login` */
export interface APIUserLoginResponse {
  /** Encoded access JWT */
  access: string;
  /** Encoded refresh JWT */
  refresh: string;

  /** Optional error message */
  detail?: string;

  /** PBKDF2 parameters for deriving fernet encryption key */
  user_keys: { key: string; salt: string }[] | null;
}

/** Response body of `POST /user/login/refresh/` */
export interface APIUserRefreshResponse {
  /** New encoded access JWT */
  access: string;
}

/** Data object representing a group a user is part of */
export interface APIUserGroup {
  /** Integer primary key of the group (e.g. `1`) */
  id: number;
  /** DUID prefix for this group (e.g. `CRIL`) */
  projectid: string;
  /** Friendly name for this group (e.g. `Cambridge Respiratory Innovations`) */
  name: string;
}

/** Response body of `GET /user/info/` */
export interface APIUserInfoResponse {
  /** User's username (email address) */
  username: string;

  /** User's first name (given name) */
  first_name: string;

  /** User's last name (surname) */
  last_name: string;

  /** List of groups the user belongs to */
  groups: APIUserGroup[];

  /** PBKDF2 parameters for deriving fernet encryption key */
  user_keys: { key: string; salt: string }[] | null;

  /**
   * List of Django permissions the user has. We're only interested in:
   *
   * - `camrest.view_detail`: does the user have permission to view the "User Detail" page?
   * - `camrest.device_assign`: does the user have permission to allocate handsets?
   */
  perms: string[];

  /** Whether the user has consented to the EULA */
  consented_to_eula: boolean;
}

/** Response body of `GET /device/` */
export type APIUDIListResponse = { handset_udi: string; group: number }[];

/** Format of individual item in the response body of `GET /duid/` */
export interface APIDUID {
  /** Integer primary key of the DUID */
  id: number;
  /** Actual string duid (e.g. `CRIL-1`) */
  duid: string;
  /** Encrypted additional info currently attached to this DUID */
  add_info: string | null;
  /** Current UDI of the device this duid is assigned to, may be null if the DUID is inactive */
  handset_udi: string | null;
  /** Numeric group IDs (primary keys) this DUID is currently attached to */
  groups: number[];
  /** Numeric disease types (primary keys) this DUID is currently attached to */
  disease_type: number[];
  /** Number of capnograms that have been recorded for this DUID */
  capnogram_count?: number;
  /** ISO format date of last breath record */
  latest_capnogram_dt?: string;
  /** Overall average breath quality (in the past 6 months) */
  capnogram_breath_quality?: number;
  /** Breath quality of the most breath record */
  latest_capnogram_breath_quality?: number;
  /** Adherence to using the device twice per day as required by the IFU (in the past 6 months) */
  adherence?: number;
}

/** Representation of a Handset UDI that's more suited to how they're used on the frontend, containing the group ID */
export interface HandsetUDI {
  /** Actual UDI of the handset */
  udi: string;
  /** Numeric group ID (primary key) this handset is currently attached to */
  groupId: number;
}

/** Representation of a DUID that's more suited to how they're used on the frontend */
export interface DUID {
  /** Full duid (e.g. `MY-STUDY-10`) */
  duid: string;
  /** Study this duid is part of (e.g. `MY-STUDY`) */
  duidStudy: string;
  /** Number within the study (e.g. `10`). Will be set to `Infinity` for sorting reasons if the DUID is not numeric. */
  duidNumber: number;
  /** Raw encrypted additional info currently attached to this DUID */
  additionalInfo: string | null;
  /** Decrypted version of [[additionalInfo]] */
  decryptedAdditionalInfo: DeviceAdditionalInfo;
  /** Numeric group IDs (primary keys) this DUID is currently attached to */
  groupIds: number[];
  /** Current UDI of the device this duid is assigned to, may be null if the DUID is inactive */
  udi: string | null;
  /** Extra information that's only needed in the list view */
  listViewExtra: DUIDListViewExtra;
}

/** Data object containing extra DUID information that's only needed by the [[PanelList | List View]] */
export interface DUIDListViewExtra {
  /** Number of capnograms that have been recorded for this duid */
  capnogramCount?: number;
  /** Date of last capnogram recorded for the duid */
  latestCapnogramDate?: Date;
  /** Average breath quality in the past 6 months */
  overallBreathQuality?: number;
  /** Breath quality of latest capnogram */
  latestBreathQuality?: number;
  /** Adherence to using the device twice per day as required by the IFU (in the past 6 months) */
  adherence?: number;
}

/** Format of individual item in the response body of `GET /capnogram/` (limited information about a capnogram) */
export interface APICapnogramListItemResponse {
  /** UUID of the capnogram */
  id: string;
  /** DUID the capnogram is associated with */
  duid: string;
  /** Version of parametrisation, only used to check for "ERROR"s */
  data_par_ver: string | null;
  /** Date the capnogram was taken (ISO format) */
  capnogram_dt: string;
}

/** Response body of `GET /capnogram/` */
export type APICapnogramListResponse = APICapnogramListItemResponse[];

/** Response body of `GET /capnogram/<id>` (all data associated with a capnogram) */
export interface APICapnogramResponse extends APICapnogramListItemResponse {
  /** UDI of the handset used to record the capnogram */
  handset_udi: string;
  /** UDI of the respa used to record the capnogram */
  respa_udi: string;

  /** Dataset number: index of the record from all records taken on the device */
  dataset_no: number;
  /** Raw calibration data */
  data_calib: number[];
  /** Raw noisy breath data */
  data_breath: number[];
  /** Raw de-noised breath data */
  data_clean: number[];
  /** Raw noise signal removed from [[data_breath]] to get [[data_clean]] */
  data_noise: number[];
  /** Indices of the breath splits in [[data_breath]], [[data_clean]] and [[data_noise]] */
  data_splits: number[];
  /** Object mapping parameter key to values of the form `[value, stdev]` */
  data_par_breath_avg: { [key: string]: number[] } | null;
  /** Object mapping parameter key to value */
  data_par_cap: { [key: string]: number } | null;
  /** Array of errors for each breath */
  data_par_error: string[];
  /** Average waveform: list of points of the form `[x, y, stdev]` */
  data_avg_waveform: number[][];
}

/**
 * Format of individual item in the response body of `POST /duid/param/` (parameter values at a specific time when
 * a record was taken)
 */
export interface APIDeviceTrendDataPoint {
  /** Date in ISO format (e.g. `2018-04-06T15:17:30Z`) */
  capnogram_dt: string;
  /** Object mapping parameter key to values of the form `[value, stdev]` */
  data_par_breath_avg: { [key: string]: number[] } | null;
  /** Object mapping parameter key to value */
  data_par_cap: { [key: string]: number } | null;
}

/** Data object containing the tokens for a logged in user, optionally with a refresh token */
export interface User {
  /** Parsed access JWT */
  accessToken: any;
  /** Expiry date of the access JWT */
  accessTokenExp: Date;
  /** Raw encoded access JWT */
  rawAccessToken: string;
  /** Parsed access JWT */
  refreshToken?: any;
  /** Expiry date of the access JWT */
  refreshTokenExp?: Date;
  /** Raw encoded access JWT */
  rawRefreshToken?: string;
}

/**
 * Type for an option in a dropdown menu. Can either be just a string or an object. If it's just a string, the same
 * value will be used for `value` and `name`. `tooltip` doesn't actually use the [[Tooltip]] component, just the `title`
 * attribute.
 */
export type DropdownOption =
  | string
  | { value: string | number; name: string; tooltip?: string };

interface Waveform {
  sampleTimes: number[];
  sampleValues: number[];
}

interface WaveformWithStd extends Waveform {
  sampleStdDeviations: number[];
}

export interface CapnogramRaw {
  /** UUID of the capnogram */
  id: string;
  /** Human readable identifier of the "test" */
  testIdentifier: string;
  /** Unique Device Identifier (UDI) of the handset the capnogram was taken with */
  handsetUdi: string;
  /** Reference of the upload  */
  uploadRef: number;
  /** Datetime when the capnogram was recorded according to the handset */
  capturedAt: Date;
  /** Datetime when the server received the upload from the handset */
  receivedAt: Date;
  /** Array of de-noised breath data */
  breathWaveform: Waveform | null;
  /** Dashboard user who conducted the test */
  administeredBy: string;
}

export interface CapnogramComputedFeatures {
  /** UUID of the capnogram */
  id: string;
  /** Array of points describing the average waveform */
  breathWaveformAverage: WaveformWithStd | null;
  /** Indices of the breath splits in [[CapnogramRaw.breathWaveform]] */
  breathWaveformSplits: number[];
  /** Array of errors for each breath */
  breathErrors: string[];
  /** Comparison series to show on the waveform */
  healthyBreathWaveformUrl: string | null;
  /** Standardised version of the waveform */
  standardisedBreathWaveform: number[] | null;
  /** Human readable identifier of the "test" */
  testIdentifier: string;
}

interface DiagnosticResult {
  /** UUID of the diagnostic result */
  id: string;
  /** UUID of associated capnogram */
  capnogramId: string;
  /** Server time the result was computed  */
  createdAt: Date;
  /** The product of the diagnosis */
  product: string;
}

export type DiagnosticResultNts = DiagnosticResult & {
  product: "nts";
  assessmentProbability: number;
};

export type DiagnosticResultNtd = DiagnosticResult & {
  product: "ntd";
  ancillaryFeatures: null | {
    smokingPackYears: number;
    smokingPackYearsProvided: boolean;
  };
  assessmentProbabilityCategory: string;
  assessmentSeverityCategory: string | null;
  modelPerformanceMetrics: {
    metricExplanation: string;
    metricLabel: string;
    metricValue: number | null;
  };
};

export interface Group {
  /** Identifier of the group */
  id: number;
  /** Code or prefix associated with the group, for example "CRIL" */
  prefix: string;
}

export interface HandsetV2 {
  /** Unique Device Identifier (UDI) of the handset the capnogram was taken with */
  udi: string;
  /** Serial number part of the UDI as a number, i.e. without zero padding */
  serialNumber: number;
  /** Serial number part of the UDI as a string including zero padding */
  serialNumberStr: string;
  /** Type of handset model, for example NTC or NTH */
  handsetModel: string;
  /** Product associated with the handset */
  product: "ntc" | "nts" | "ntd";
  /** Current group associated with the handset */
  group: Group;
  /** Who is using the device. Null implies not locked */
  lockedBy: string | null;
}

/** API responses return a string instead of date object.
 * Takes an object and recursively switches Date for string.
 * Does not check arrays.
 */
export type StringifiedDate<T extends { [key: string]: any }> = {
  [key in keyof T]: T[key] extends Date
    ? string
    : T[key] extends { [key: string]: any }
    ? StringifiedDate<T[key]>
    : T[key];
};
