
import { defineComponent } from "vue";
import {
  NTS_DASHBOARD_IFU_FILE,
  NTS_DASHBOARD_IFU_IMAGE,
  NTH_HANDSET_IFU_FILE,
  NTH_HANDSET_IFU_IMAGE,
  NTS_PRODUCT_LABEL_FILE,
  NTS_PRODUCT_LABEL_IMAGE,
  DASHBOARD_ASSET_BUCKET_PREFIX,
} from "@/config";
import PanelFooter from "@/views/panels/dashboard/PanelFooter.vue";

export default defineComponent({
  name: "NtsInstructions",
  components: { PanelFooter },
  data() {
    return {
      productLabelImagePath: `${DASHBOARD_ASSET_BUCKET_PREFIX}/${NTS_PRODUCT_LABEL_IMAGE}`,
      productLabelFilePath: `${DASHBOARD_ASSET_BUCKET_PREFIX}/${NTS_PRODUCT_LABEL_FILE}`,
      pathImageDashboard: `${DASHBOARD_ASSET_BUCKET_PREFIX}/${NTS_DASHBOARD_IFU_IMAGE}`,
      pathImageHandset: `${DASHBOARD_ASSET_BUCKET_PREFIX}/${NTH_HANDSET_IFU_IMAGE}`,
      pathPdfDashboard: `${DASHBOARD_ASSET_BUCKET_PREFIX}/${NTS_DASHBOARD_IFU_FILE}`,
      pathPdfHandset: `${DASHBOARD_ASSET_BUCKET_PREFIX}/${NTH_HANDSET_IFU_FILE}`,
    };
  },
});
