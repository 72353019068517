import type { RouteConfig } from "vue-router";

import ViewDashboard from "@/views/common/ViewDashboard.vue";
import Instructions from "@/views/nts/NtsInstructions.vue";
import ResultsPage from "@/views/notNtc/ResultsPage.vue";
import SelectHandset from "@/views/notNtc/SelectHandset.vue";
import ResultsHistory from "@/views/notNtc/ResultsHistory.vue";
import StartTest from "@/views/nts/StartTest.vue";
import LoadingTest from "@/views/notNtc/LoadingTest.vue";
import PanelPrivacy from "@/views/panels/dashboard/privacy/PanelPrivacy.vue";

// Nts routes
const route: RouteConfig = {
  path: "/nts",
  component: ViewDashboard,
  meta: {
    requiresLogin: true,
    requiresNtsUiPermission: true,
    selectedProduct: "nts",
  },
  children: [
    { path: "", name: "nts", redirect: { name: "nts-handset" } },
    {
      path: "instructions",
      name: "nts-instructions",
      component: Instructions,
      meta: { order: 1, title: () => "Documentation" },
    },
    {
      path: "results/:capnogramId",
      name: "nts-result-view",
      component: ResultsPage,
      meta: { order: 1, title: () => "Test Result" },
    },
    {
      path: "results-history",
      name: "nts-results-history",
      component: SelectHandset,
      meta: {
        order: 1,
        title: () => "Results History",
      },
      children: [
        {
          path: ":udi",
          name: "nts-handset-results",
          component: ResultsHistory,
          meta: { order: 1, title: () => "Result History" },
        },
      ],
    },
    {
      path: "handsets",
      name: "nts-handset",
      component: SelectHandset,
      meta: {
        order: 1,
        title: () => "Handset",
        requiresLocking: true,
      },
      children: [
        {
          path: ":udi/start-test",
          name: "nts-start-test",
          component: StartTest,
          meta: { order: 1, title: () => "Start Test" },
        },
        {
          path: ":udi/loading-test",
          name: "nts-loading-test",
          component: LoadingTest,
          meta: { order: 1, title: () => "Loading Test" },
        },
      ],
    },
    {
      path: "privacy",
      name: "nts-privacy",
      component: PanelPrivacy,
      meta: {
        requiresLogin: true,
        order: 1,
        title: () => "Privacy Policy",
      },
    },
  ],
};

export default route;
