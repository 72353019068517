
import Vue from "vue";
import Component from "vue-class-component";
import { FOOTER_WARNING, GIT_SHA, VERSION } from "@/config";
import { MUTATION_SHOW_EULA_DIALOG } from "@/store/constants";

/**
 * Panel displayed at the bottom of dashboard views. Contains copyright, help and privacy policy links.
 *
 * ![](media://footer.png)
 */
@Component({
  name: "panel-footer",
})
export default class PanelFooter extends Vue {
  appVersion = VERSION;
  gitSha = GIT_SHA;
  warning = FOOTER_WARNING;

  /** Displays the EULA dialog with just a close button */
  showEULA() {
    this.$store.commit(MUTATION_SHOW_EULA_DIALOG);
  }

  get isProductNtc(): boolean {
    return this.$store.getters.isCurrentProduct(this.$route, "ntc");
  }

  get isProductNts(): boolean {
    return this.$store.getters.isCurrentProduct(this.$route, "nts");
  }
}
