import { MUTATION_ADD_ERROR } from "@/store/constants";
import { defineComponent } from "vue";

const products = ["nts", "ntd"] as const;
type Product = (typeof products)[number];

const productMixin = defineComponent({
  computed: {
    product(): Product {
      for (const product of products) {
        if (this.$store.getters.isCurrentProduct(this.$route, product)) {
          return product;
        }
      }
      this.$store.commit(MUTATION_ADD_ERROR, {
        message: "Route not associated with a product",
      });
      throw new Error("Route not associated with a product");
    },
  },
});

export { products, Product, productMixin };
