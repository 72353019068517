import { defineComponent } from "vue";

import {
  ntsPrefix,
  ActionTypes,
  UnlockReasons,
  GetterTypes,
} from "@/store/internal/modules/nts";
import { State } from "@/store/internal/state";
import { Store } from "@/store";
import { LockingStatusWatcher } from "@/store/fetcher";
import { APIUserGroup, HandsetV2 } from "@/store/types";
import { MUTATION_ADD_ERROR } from "@/store/constants";

const enum LockType {
  NO_LOCK,
  USER_LOCKED,
  EXTERNAL_LOCK,
}

export default defineComponent({
  name: "SelectHandset",
  data() {
    return {
      watchers: new Map<APIUserGroup["projectid"], LockingStatusWatcher>(),
    };
  },
  computed: {
    requiresLocking(): boolean {
      // Defined in parent component
      throw new Error("NOT IMPLEMENTED");
      return false;
    },
    product(): "nts" | "ntd" {
      // Defined in parent component
      throw new Error("NOT IMPLEMENTED");
      return "nts";
    },
    lockingGroups(): null | APIUserGroup[] {
      if (!this.requiresLocking) return null;
      return (this.$store.state as State).userInfo?.groups ?? null;
    },
    routeUdi(): null | string {
      return this.$route.params.udi ?? null;
    },
    lockStatus(): null | LockType {
      if (!this.requiresLocking) return null;
      const udi = this.routeUdi;
      if (!udi) return null;
      const handset: HandsetV2 | null =
        this.$store.getters[ntsPrefix(GetterTypes.GET_HANDSET_BY_UDI)](udi);
      if (!handset) return null;
      const username = this.$store.state.userInfo.username;
      if (handset.lockedBy === username) return LockType.USER_LOCKED;
      if (handset.lockedBy === null) return LockType.NO_LOCK;
      return LockType.EXTERNAL_LOCK;
    },
  },
  methods: {
    unlock(udi: string) {
      return this.$store.dispatch(ntsPrefix(ActionTypes.UNLOCK_HANDSET), {
        handsetUdi: udi,
        unlockReason: UnlockReasons.userNavigatedAway,
      });
    },
    lock(udi: string) {
      return this.$store.dispatch(ntsPrefix(ActionTypes.LOCK_HANDSET), {
        handsetUdi: udi,
      });
    },
    clearWatchers(): void {
      for (const watcher of this.watchers.values()) {
        watcher.dispose();
      }
    },
    async destroy(event: Event | null = null) {
      this.clearWatchers();
      if (!this.requiresLocking) return;
      if (!this.routeUdi) return;
      // TODO: CAM-496 Fix unlocking for firefox (see comment below)
      // https://camresp.atlassian.net/browse/CAM-496
      // Added to prevent firefox navigating away before unlocking
      // https://stackoverflow.com/questions/704561/ns-binding-aborted-shown-in-firefox-with-httpfox#comment123840160_22013028
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      // event?.preventDefault();
      await this.unlock(this.routeUdi);
    },
  },
  async beforeRouteLeave(_, from, next) {
    this.clearWatchers();
    if (!this.requiresLocking) {
      next();
      return;
    }
    if (from.params.udi) await this.unlock(from.params.udi);
    window.removeEventListener("beforeunload", this.destroy);
    next();
  },
  created() {
    if (!this.requiresLocking) return;
    window.addEventListener("beforeunload", this.destroy);
  },

  watch: {
    routeUdi: {
      async handler(newUdi, oldUdi) {
        if (!this.requiresLocking) return;
        if (!oldUdi && !newUdi) return;
        if (!newUdi) {
          this.unlock(oldUdi);
          return;
        }
        await this.lock(newUdi);
      },
      immediate: true,
    },
    lockStatus: {
      handler(newLockStatus: null | LockType) {
        if (newLockStatus === null) return;
        if (newLockStatus === LockType.USER_LOCKED) return;
        if (newLockStatus === LockType.EXTERNAL_LOCK) {
          const handset: HandsetV2 = this.$store.getters[
            ntsPrefix(GetterTypes.GET_HANDSET_BY_UDI)
          ](this.routeUdi);
          this.$store.commit(MUTATION_ADD_ERROR, {
            message: `This handset is locked by ${handset.lockedBy}.`,
          });
          this.$router.replace({ name: this.product });
          return;
        }
        // TODO: Add behaviour for LockType.NO_LOCK
        // https://camresp.atlassian.net/browse/CAM-474
      },
      immediate: true,
    },
    lockingGroups: {
      handler(
        newGroups: null | APIUserGroup[],
        oldGroups: null | APIUserGroup[]
      ) {
        let removedGroups: APIUserGroup[] = [];
        let addedGroups: APIUserGroup[] = [];
        if (newGroups) {
          if (oldGroups) {
            addedGroups = newGroups.filter(
              (group) => !oldGroups.includes(group)
            );
          } else {
            addedGroups = newGroups;
          }
        }
        if (oldGroups) {
          if (newGroups) {
            removedGroups = oldGroups.filter(
              (group) => !newGroups.includes(group)
            );
          } else {
            removedGroups = oldGroups;
          }
        }
        for (const group of addedGroups) {
          this.watchers.set(
            group.projectid,
            new LockingStatusWatcher(
              group.projectid,
              (this.$store as Store<any>).pusher!
            )
          );
        }
        for (const group of removedGroups) {
          this.watchers.get(group.projectid)?.dispose;
          this.watchers.delete(group.projectid);
        }
      },
      immediate: true,
    },
  },
});
