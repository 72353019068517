
import { defineComponent } from "vue";
import Loader from "@/components/Loader.vue";
import { HandsetUDI } from "@/store/types";
import { timerMixin } from "@/components/mixins/timerMixin";

export default defineComponent({
  components: {
    Loader,
  },
  mixins: [timerMixin],
  computed: {
    handset(): HandsetUDI | undefined {
      return this.$store.state.userUDIs.find(
        (handset: HandsetUDI) => handset.udi === this.$route.params.udi
      );
    },
  },
});
