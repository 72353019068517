
import { defineComponent } from "vue";
import CardDialog from "@/components/CardDialog.vue";
import Dropdown from "@/components/Dropdown.vue";
import TextInput from "@/components/TextInput.vue";
import { MUTATION_SET_NTD_FEEDBACK_DIALOG } from "@/store/constants";

export default defineComponent({
  name: "DialogDiagnosisFeedback",
  components: { CardDialog, Dropdown, TextInput, },
  data() {
    return {
      testId: "",
      error: "",
      diagnosisAgrees: "",
      diagnosisFinal: "",
      ynOptions: ["Yes", "No"],
      diagnosisOptions: [
        "Pre-COPD",
        "ACOS",
        "Anaemia",
        "Asthma",
        "Breathing Pattern Disorder",
        "Bronchiectasis",
        "Congestive Cardiac Failure",
        "Extrinsic Allergic Alveolitis",
        "GOLD 1 COPD",
        "GOLD 2 COPD",
        "GOLD 3 COPD",
        "GOLD 4 COPD",
        "Gastro-oesophageal Reflux Disease",
        "Hypersentivity Pneumonitis",
        "Lung Cancer",
        "Obesity Hypoventilation Syndrome",
        "Occupational Lung Disease",
        "Pneumonia",
        "Post-COVID syndrome",
        "Post-nasal drip",
        "Pulmonary Fibrosis",
        "Sarcoidosis",
        "Upper Airway Obstruction (e.g. sleep apnoea)",
        "Tracheobroncomalacia",
        "None of the Above",
      ],
    };
  },
  methods: {
    close() {
      this.$store.commit(MUTATION_SET_NTD_FEEDBACK_DIALOG, false);
    }
  }
});
