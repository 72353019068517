import Vue from "vue";
import {
  NTS_ACCEPTED_CAPNOGRAM_WINDOW_MINS,
  NTS_TEST_FAILURE_TIMEOUT_SECS,
} from "@/config";
import { UploadLifecycleParsed } from "@/store/internal/modules/nts";
import { productMixin } from "@/components/mixins/product";

export default Vue.extend({
  mixins: [productMixin],
  data(): {
    targetNotification: UploadLifecycleParsed | null;
    failureNotification: UploadLifecycleParsed | null;
    failureTimeout: number | null;
  } {
    return {
      targetNotification: null,
      failureNotification: null,
      failureTimeout: null,
    };
  },
  computed: {
    mostRecentNotification() {
      // unit test break if I try to use GetterTypes and ntsPrefix
      return this.$store.getters["nts/MOST_RECENT_LIFE_CYCLE_NOTIFICATION"];
    },
  },
  destroyed() {
    this.cancelFailure();
  },
  methods: {
    /**
     * Fetch all the capnogram data including, raw data, computed features and
     * the diagnostic result.
     * @param capnogramId - capnogram identifier
     * @returns promise all of the API request
     */
    fetchCapnogramData(capnogramId: string) {
      return Promise.all([
        this.$store.dispatch("nts/GET_CAPNOGRAM_RAW", capnogramId),
        this.$store.dispatch(
          "nts/GET_CAPNOGRAM_COMPUTED_FEATURES",
          capnogramId
        ),
        this.$store.dispatch(
          "nts/GET_CAPNOGRAM_DIAGNOSTIC_RESULT",
          capnogramId
        ),
      ]);
    },
    /**
     * Begins the failure timeout and sets the failure notification.
     * @param notification - notification which trigger a failure
     * @returns void
     */
    startFailure(notification: UploadLifecycleParsed) {
      if (this.failureTimeout !== null) {
        // eslint-disable-next-line no-console
        console.log("Failure timer has already begun");
        return;
      }

      // eslint-disable-next-line no-console
      console.log("Starting failure timer");
      this.failureTimeout = window.setTimeout(
        this.failureCallback,
        NTS_TEST_FAILURE_TIMEOUT_SECS * 1000
      );
      this.failureNotification = notification;
    },
    /**
     * Cancels the failure timeout and resets the failure notification.
     * @returns void
     */
    cancelFailure() {
      if (this.failureTimeout === null) return;

      // eslint-disable-next-line no-console
      console.log("Cancelling failure timer");
      window.clearTimeout(this.failureTimeout);
      this.failureTimeout = null;
      this.failureNotification = null;
    },
    /**
     * The callback used when the failure timer expires. This can be
     * overwritten to customise the behaviour.
     */
    failureCallback() {
      this.$store.dispatch("nts/REPORT_BREATH_RECORD_FAILED_STATUS", true);
    },
    /**
     * Reset the listener state. Called from parent component.
     */
    resetListener() {
      this.targetNotification = null;
      this.failureNotification = null;
      this.cancelFailure();
    },
  },
  watch: {
    async mostRecentNotification(newNotification: UploadLifecycleParsed) {
      // eslint-disable-next-line no-console
      console.log(
        `Notification received: ${newNotification.event.stage} ${newNotification.event.state}`
      );

      // ignore notifications without capnogram ID or captured at
      // remaining notifications will be PROCESS and DIAGNOSE
      if (
        !newNotification ||
        !newNotification.capnogramId ||
        !newNotification.capturedAt
      )
        return;

      const timeSinceFromCapnogramInMs =
        Date.now() - newNotification.capturedAt.getTime();

      // update the target notification if it has not yet been set or
      // if the new notification capturedAt is after the current
      // targetNotification capturedAt
      if (
        this.targetNotification === null ||
        this.targetNotification.capturedAt < newNotification.capturedAt
      ) {
        // only update the target notification if the time since new
        // notification is less than the accepted window
        if (
          timeSinceFromCapnogramInMs <
          NTS_ACCEPTED_CAPNOGRAM_WINDOW_MINS * 60 * 1000
        ) {
          this.targetNotification = newNotification;
          // eslint-disable-next-line no-console
          console.log(
            `Updated target capnogram to: ${this.targetNotification.capnogramId}`
          );
          this.cancelFailure();
        }
      }

      // ignore notification if no target notification has been set or the
      // target and new notification capnogram ID have different
      if (newNotification.capnogramId !== this.targetNotification?.capnogramId)
        return;

      // if there is a failed state received begin the failure starter and store
      // the failure notification
      if (newNotification.event.state === "FAILED") {
        this.startFailure(newNotification);
        return;
      }

      // cancel the failure notification if the new notification is the success
      // of the failed stage
      if (
        this.failureNotification &&
        this.failureNotification.event.stage === newNotification.event.stage &&
        newNotification.event.state === "SUCCEEDED"
      ) {
        this.cancelFailure();
      }

      // ignore all none DIAGNOSE_* SUCCEEDED notifications
      if (
        newNotification.event.state !== "SUCCEEDED" ||
        !["DIAGNOSE_INITIAL", "DIAGNOSE_FINAL"].includes(
          newNotification.event.stage
        )
      )
        return;

      // regardless of product or stage we cancel the cancel failure
      this.cancelFailure();

      // NTS we navigate on any successful diagnose event
      if (this.product === "nts") {
        await this.fetchCapnogramData(this.targetNotification.capnogramId);
        this.$router.replace({
          name: "nts-result-view",
          params: { capnogramId: this.targetNotification.capnogramId },
        });
      }

      if (this.product === "ntd") {
        if (newNotification.event.stage === "DIAGNOSE_INITIAL") {
          this.$router.push({
            name: "ntd-smoking-history",
            params: { capnogramId: this.targetNotification.capnogramId },
          });
          return;
        }

        if (newNotification.event.stage === "DIAGNOSE_FINAL") {
          await this.fetchCapnogramData(this.targetNotification.capnogramId);
          this.$router.replace({
            name: "ntd-result-view",
            params: { capnogramId: this.targetNotification.capnogramId },
          });
        }
      }
    },
  },
});
