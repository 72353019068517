import { PropType, defineComponent } from "vue";
import { EnrichedCapnogram } from "@/store/internal/modules/nts";

// hack to allow the child component to define where "capnogram" is defined
// i.e. capnogram could be a prop or computed property
interface Props {
  capnogram: EnrichedCapnogram | null;
}

interface Computed {
  administeredBy: string;
  assessmentProbabilityCategory: string | null;
  metricExplanation: string | null;
  severity: string | null;
  smokingHistory: string;
}

const formatNtdResultsMixin = defineComponent<Props, {}, Computed>({
  computed: {
    administeredBy() {
      return this.capnogram?.raw?.administeredBy ?? "Unknown";
    },
    assessmentProbabilityCategory() {
      const diagnosticResult = this.capnogram?.diagnosticResult;
      if (diagnosticResult?.product !== "ntd") return null;

      return diagnosticResult.assessmentProbabilityCategory;
    },
    metricExplanation() {
      const diagnosticResult = this.capnogram?.diagnosticResult;
      if (diagnosticResult?.product !== "ntd") return null;
      const rawExplanation =
        diagnosticResult.modelPerformanceMetrics.metricExplanation;
      return rawExplanation.replace(/(\r\n|\r|\n)/g, "<br>");
    },
    severity() {
      const diagnosticResult = this.capnogram?.diagnosticResult;
      if (diagnosticResult?.product !== "ntd") return null;
      return diagnosticResult.assessmentSeverityCategory;
    },
    smokingHistory() {
      const diagnosticResult = this.capnogram?.diagnosticResult;

      const unknownSmokingHistory = "Not entered";
      if (diagnosticResult?.product !== "ntd") return unknownSmokingHistory;

      if (!diagnosticResult.ancillaryFeatures?.smokingPackYearsProvided)
        return unknownSmokingHistory;

      const packYears = diagnosticResult.ancillaryFeatures.smokingPackYears;
      const strPackYears = packYears.toFixed(0);

      if (strPackYears === "1") return `${strPackYears} pack year`;
      return `${strPackYears} pack years`;
    },
  },
});

export { formatNtdResultsMixin };
