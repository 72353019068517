
import Vue from "vue";
import Component from "vue-class-component";
import SidebarItem from "@/views/panels/dashboard/SidebarItem.vue";

import IconSidebarLogo from "@/assets/icons/IconSidebarLogo.vue";
import IconBars from "@/assets/icons/IconBars.vue";
import IconTrends from "@/assets/icons/IconTrends.vue";
import IconBlank from "@/assets/icons/IconBlank.vue";
import IconHelp from "@/assets/icons/IconHelp.vue";
import IconExit from "@/assets/icons/IconExit.vue";
import IconInfo from "@/assets/icons/IconInfo.vue";
import IconDocument from "@/assets/icons/IconDocument.vue";
import IconPlay from "@/assets/icons/IconPlay.vue";
import IconExclamation from "@/assets/icons/IconExclamation.vue";
import IconAdd from "@/assets/icons/IconAdd.vue";
import {
  MUTATION_SET_PRODUCT_LABELING_DIALOG,
  MUTATION_SET_NTD_FEEDBACK_DIALOG,
} from "@/store/constants";
import { Getter, State } from "vuex-class";
import { Prop } from "vue-property-decorator";
import LogoText from "@/components/LogoText.vue";

/**
 * Component for the main navigation sidebar. On desktop, can be toggled
 * between a collapsed and expanded state (persisted between sessions).
 * See [[SidebarItem]] for the component used by individual navigation links.
 */
@Component({
  name: "sidebar",
  components: {
    SidebarItem,
    IconSidebarLogo,
    IconBars,
    IconTrends,
    IconBlank,
    IconHelp,
    IconExit,
    IconDocument,
    IconInfo,
    IconExclamation,
    IconPlay,
    IconAdd,
    LogoText,
  },
})
export default class Sidebar extends Vue {
  /**
   * See [[State.deviceDUID]]
   * @category Vuex Binding
   */
  @State("deviceDUID") readonly deviceDUID!: string;
  /**
   * See [[src/store/internal/getters.hasDetailPermission |
   * hasDetailPermission]] getter. Used to determine whether the DUID section
   * and detail view navigation items should be shown.
   * @category Vuex Binding
   */
  @Getter("hasDetailPermission") readonly hasDetailPermission!: boolean;

  /**
   * Whether the sidebar is currently expanded. Should be bound via v-model.
   * @category Vue Property
   */
  @Prop({ type: Boolean, required: true }) readonly value!: boolean;

  /** Open the product labelling dialog */
  openProductLabellingDialog() {
    this.$store.commit(MUTATION_SET_PRODUCT_LABELING_DIALOG, true);
  }
  /** Open the NTD feedback dialog */
  openNtdFeedbackDialog() {
    this.$store.commit(MUTATION_SET_NTD_FEEDBACK_DIALOG, true);
  }

  /**
   * Click handler for the sidebar. Checks if a [[SidebarItem]] was the clicked
   * element. If it wasn't, toggles the sidebar state.
   */
  toggle(event: MouseEvent) {
    // check if a SidebarItem is anywhere in the parents of the clicked node,
    // if it is, we don't want to toggle the expanded state, we're navigating instead
    // and collapsing the sidebar
    if (this.isProductNts || this.isProductNtd) return;
    const element = event.target as HTMLElement;
    const hasSidebarItem = element.closest(".sidebar-item");

    if (hasSidebarItem) this.$emit("input", false);
    else this.$emit("input", !this.value);
  }
  get isProductNtc(): boolean {
    return this.$store.getters.isCurrentProduct(this.$route, "ntc");
  }
  get isProductNts(): boolean {
    return this.$store.getters.isCurrentProduct(this.$route, "nts");
  }
  get isProductNtd(): boolean {
    return this.$store.getters.isCurrentProduct(this.$route, "ntd");
  }
}
