import { defineComponent } from "vue";
import {
  ntsPrefix,
  GetterTypes,
  EnrichedCapnogram,
} from "@/store/internal/modules/nts";
import { HandsetV2 } from "@/store/types";
import { formatDate } from "@/components/graphics/date";
import type { ChartCapnogram } from "@/store/fetcher/capnogram";
import { productMixin } from "@/components/mixins/product";

const formattedResultsMixin = defineComponent({
  mixins: [productMixin],
  computed: {
    // Here for type-safety. Should be implemented on the parent component.
    capnogramId(): string {
      return "";
    },

    handset(): HandsetV2 | null {
      return null;
    },

    capnogram(): EnrichedCapnogram | null {
      return this.$store.getters[ntsPrefix(GetterTypes.GET_CAPNOGRAM_BY_ID)](
        this.capnogramId
      );
    },

    capnogramChart(): ChartCapnogram | null {
      return this.$store.getters[
        ntsPrefix(GetterTypes.GET_CHART_CAPNOGRAM_BY_ID)
      ](this.capnogramId);
    },

    testIdentifier(): string | null {
      return this.capnogram?.raw?.testIdentifier ?? null;
    },

    handsetSerial(): string | null {
      return this.handset?.serialNumberStr ?? null;
    },

    formattedDate(): string | null {
      const raw = this.capnogram?.raw;
      if (!raw) return null;

      return formatDate(raw.capturedAt);
    },

    capnogramStartTime(): string | null {
      return this.$store.getters[
        ntsPrefix(GetterTypes.GET_CAPNOGRAM_START_TIME_BY_ID)
      ](this.capnogramId);
    },
  },
});

export { formattedResultsMixin };
