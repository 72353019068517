
import { defineComponent, PropType } from "vue";
import IconInfo from "@/assets/icons/IconInfo.vue";
import { EnrichedCapnogram } from "@/store/internal/modules/nts";

export default defineComponent({
  props: {
    capnogram: {
      type: Object as PropType<EnrichedCapnogram | null>,
      required: true,
    },
  },
  data() {
    return { probabilityInfoIconHover: false };
  },
  components: {
    IconInfo,
  },
  computed: {
    formattedProbability(): string | null {
      const diagnosticResult = this.capnogram?.diagnosticResult;
      if (diagnosticResult?.product !== "nts") return null;

      const assessmentProbability =
        diagnosticResult.assessmentProbability * 100;
      return `${assessmentProbability.toFixed(0)}%`;
    },
  },
});
