
import { defineComponent } from "vue";

import Loader from "@/components/Loader.vue";
import { formattedResultsMixin } from "@/components/mixins/formattedResultsMixin";
import { productMixin, Product } from "@/components/mixins/product";
import NtdDiagnosis from "@/components/diagnosis/NtdDiagnosis.vue";
import NtsDiagnosis from "@/components/diagnosis/NtsDiagnosis.vue";
import NtsWaveform from "@/components/diagnosis/NtsWaveform.vue";
import NtdWaveform from "@/components/diagnosis/NtdWaveform.vue";
import NtdTestInput from "@/components/diagnosis/NtdTestInput.vue";
import errorReporter, { HttpResponseError, NotFoundError } from "@/errors";
import {
  ntsPrefix,
  ActionTypes,
  GetterTypes,
} from "@/store/internal/modules/nts";
import PanelCapnogram from "@/views/panels/dashboard/detail/PanelCapnogram.vue";
import PanelFooter from "@/views/panels/dashboard/PanelFooter.vue";
import { MUTATION_ADD_ERROR, MUTATION_SET_NTD_FEEDBACK_DIALOG } from "@/store/constants";
import { ErrorLevel, HandsetV2 } from "@/store/types";

export default defineComponent({
  name: "results-page",

  components: {
    NtsDiagnosis,
    NtdDiagnosis,
    NtsWaveform,
    NtdWaveform,
    NtdTestInput,
    Loader,
    PanelCapnogram,
    PanelFooter,
  },
  data() {
    return {
      loading: true,
      probabilityInfoIconHover: false,
    };
  },
  mixins: [formattedResultsMixin, productMixin],

  methods: {
    /** Open the NTD feedback dialog */
    openNtdFeedbackDialog() {
      this.$store.commit(MUTATION_SET_NTD_FEEDBACK_DIALOG, true);
    }
  },

  computed: {
    feedbackRoute() {
      if (this.product === "nts") {
        return { name: "nts-feedback" };
      }
      return { name: "ntd-feedback" };
    },
    printRoute(): { name: string; params: { capnogramId: string } } {
      if (this.product === "nts") {
        return {
          name: "print-test-result-nts",
          params: { capnogramId: this.capnogramId },
        };
      }
      return {
        name: "print-test-result-ntd",
        params: { capnogramId: this.capnogramId },
      };
    },
    backButton(): {
      text: "Go back" | "Finish test";
      route: {
        name: `${Product}-handset-results` | `${Product}-start-test`;
        params: { udi?: string };
      };
    } {
      const product = this.product;
      const route: {
        name: `${Product}-handset-results` | `${Product}-start-test`;
        params: { udi?: string };
      } = {
        name: `${product}-start-test`,
        params: { udi: this.handset?.udi },
      };

      let text: "Go back" | "Finish test" = "Finish test";

      if (this.$route.query.results) {
        route.name = `${product}-handset-results`;
        text = "Go back";
      }

      return { text, route };
    },

    capnogramId(): string {
      return this.$route.params.capnogramId;
    },

    handset(): HandsetV2 | null {
      return this.$store.getters[
        ntsPrefix(GetterTypes.GET_HANDSET_BY_CAPNOGRAM_ID)
      ](this.capnogramId);
    },
  },

  async created() {
    // here just to be explicit - the initial value is true
    this.loading = true;
    // Fetch all the capnogram data
    // Essential Calls
    await Promise.all([
      this.$store.dispatch(
        ntsPrefix(ActionTypes.GET_CAPNOGRAM_RAW),
        this.$route.params.capnogramId
      ),
      this.$store.dispatch(
        ntsPrefix(ActionTypes.GET_CAPNOGRAM_COMPUTED_FEATURES),
        this.$route.params.capnogramId
      ),
      this.$store.dispatch(
        ntsPrefix(ActionTypes.GET_CAPNOGRAM_DIAGNOSTIC_RESULT),
        this.$route.params.capnogramId
      ),
      this.$store.dispatch(ntsPrefix(ActionTypes.GET_HANDSETS), this.product),
    ]).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);

      // open custom modal when the test result was not found
      if (error instanceof NotFoundError) {
        this.$store.commit(MUTATION_ADD_ERROR, {
          message: "The test results were not found",
          level: ErrorLevel.ERROR,
        });
      }

      // redirect if any HttpResponseErrors are thrown
      if (error instanceof HttpResponseError) {
        // redirect to the default page
        this.$router.push({ name: this.product });
      }

      errorReporter.report(error);
    });

    // Optional Api Calls
    await Promise.allSettled([
      this.$store.dispatch(
        ntsPrefix(ActionTypes.GET_HEALTHY_WAVEFORM_BY_CAPNOGRAM_ID),
        this.capnogramId
      ),
    ]).then((results) => {
      results.forEach((result) => {
        if (result.status !== "rejected") return;
        // eslint-disable-next-line no-console
        console.error(result);
        const error: Error = result.reason;
        errorReporter.report(error);
      });
    });

    this.loading = false;
  },
});
