
import Vue, { PropType } from "vue";
import { State } from "vuex-class";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { autoTick, normY } from "@/components/graphics/utils";
import IconGoTo from "@/assets/icons/IconGoTo.vue";
import Card from "@/components/Card.vue";
import Chart from "@/components/graphics/Chart.vue";
import { INTERNAL_PORTAL_URL } from "@/config";
import { ChartCapnogram } from "@/store/fetcher/capnogram";
import { APIUserInfoResponse, DUID } from "@/store/types";

const checkUsernameEndsWithDomain = (
  username: string,
  domain: string
): boolean => {
  return (
    username.lastIndexOf(domain) !== -1 &&
    username.lastIndexOf(domain) === username.length - domain.length
  );
};

/**
 * Panel displayed in [[PanelDetail]] for viewing the raw/full trace of a capnogram. Allows the user to pan/zoom in on
 * the chart. Used in the detail view and also for user training.
 *
 * ![](media://panelcapnogram.png)
 */
@Component({
  name: "panel-capnogram",
  components: { Chart, Card, IconGoTo },
})
export default class PanelCapnogram extends Vue {
  /**
   * Vuex store bindings. See [[State.userInfo]].
   * @category Vuex Binding
   */
  @State("userInfo") readonly userInfo!: APIUserInfoResponse | null;

  /**
   * Current capnogram to be displayed in the chart, or null if no capnogram is available yet
   * @category Vue Prop
   */
  @Prop() readonly capnogram!: ChartCapnogram | null;
  /** UUID of the capnogram */
  @Prop({ type: String, default: null }) readonly capnogramId!: string | null;
  /**
   * Formatted time the capnogram was recorded to be displayed in the chart header
   * @category Vue Prop
   */
  @Prop({ default: null }) readonly capnogramStartTime!: string | null;
  /**
   * Handset UDI of the [[capnogram]].
   * See [[PanelDetail.handsetUdi]].
   * @category Vue Prop
   */
  @Prop({ type: String as PropType<string | null>, default: null })
  readonly handsetUdi!: string | null;

  /** Expose [[src/components/graphics/utils.normY | normY]] to the template so
   * it can be used for formatting data points. */
  normY = normY;

  // initial values for zoom and translate, Chart requires that we're responsible for storing these
  /**
   * Current X zoom for the [[Chart]]. See [[Chart.syncedXZoom]].
   * @category Vue Data
   */
  xZoom: number = 1;
  /**
   * Current X translate for the [[Chart]]. See [[Chart.syncedXTranslate]].
   * @category Vue Data
   */
  xTranslate: number = 0;

  /** Calculates the link to the Internal Portal.
   * @returns a link to the Internal Portal or null.
   *
   * This will return null if any of the following is true:
   *  - username does not end with "\@camresp.com" or "\@tidalsense.com".
   *    TODO: Get the full handset UDI from the backend potentially when fetch
   *    the capnogram.
   *  - handsetUdi is not defined. This can be the case when the DUID is no
   *    longer allocated to the handset.
   *  - Internal Portal URL env var is not defined.
   */
  get portalLink(): string | null {
    const username = this.userInfo && this.userInfo.username;
    const camrespDomain = "@camresp.com";
    const tidalSenseDomain = "@tidalsense.com";

    if (!username) return null;

    const userEndWithCamrespOrTidalSenseDomain =
      checkUsernameEndsWithDomain(username, camrespDomain) ||
      checkUsernameEndsWithDomain(username, tidalSenseDomain);

    if (
      !this.handsetUdi ||
      !userEndWithCamrespOrTidalSenseDomain ||
      !INTERNAL_PORTAL_URL
    )
      return null;

    return `${INTERNAL_PORTAL_URL}/handsets/${this.handsetUdi}?capnogramId=${this.capnogramId}`;
  }

  /**
   * Y-axis ticks, these will be displayed with flex
   * [space-between](https://developer.mozilla.org/en-US/docs/Web/CSS/justify-content) so as long as they uniformly
   * cover the entire range, we don't need to calculate additional positioning information, just the labels.
   * @returns Tick labels that should be evenly spaced on the left axis
   * @category Vue Computed
   */
  get yTicks(): string[] {
    if (this.capnogram) {
      return autoTick(this.capnogram.scales.y[1]);
    } else {
      return [];
    }
  }

  /**
   * Make the the visual changes to the charts required by the NTS results page,
   * e.g. those in https://camresp.atlassian.net/browse/CAM-146.
   * Defaults to `false`.
   * @category Vue Prop
   */
  @Prop({ type: Boolean, default: false })
  readonly ntsResultChart!: boolean;
}
