import { NTS_LOAD_TEST_TIMEOUT_MINS } from "@/config";
import { ActionTypes, ntsPrefix } from "@/store/internal/modules/nts";
import { defineComponent } from "vue";

const timerMixin = defineComponent({
  data() {
    let interval: NodeJS.Timeout | undefined;
    return {
      interval,
    };
  },
  methods: {
    failureCallback() {
      this.$store.dispatch(
        ntsPrefix(ActionTypes.REPORT_BREATH_RECORD_FAILED_STATUS),
        true
      );
    },
  },
  mounted() {
    this.interval = setTimeout(
      this.failureCallback,
      NTS_LOAD_TEST_TIMEOUT_MINS * 60 * 1000
    );
  },
  destroyed() {
    clearTimeout(this.interval);
  },
});

export { timerMixin };
