
import { defineComponent } from "vue";

import Loader from "@/components/Loader.vue";
import { Store } from "@/store";
import { HandsetV2 } from "@/store/types";
import { MUTATION_ADD_ERROR } from "@/store/constants";
import {
  ActionTypes,
  GetterTypes,
  ntsPrefix,
} from "@/store/internal/modules/nts";
import { UploadLifecycleWatcher } from "@/store/fetcher";
import DiagnosticNotificationListenerMixin from "@/components/mixins/DiagnosticNotificationListenerMixin";
import handsetLocking from "@/components/mixins/handsetLocking";
import CardDialog from "@/components/CardDialog.vue";
import FailedBreathRecord from "@/components/errorMessages/FailedBreathRecord.vue";
import PanelFooter from "@/views/panels/dashboard/PanelFooter.vue";
import { productMixin, Product } from "@/components/mixins/product";

export default defineComponent({
  name: "SelectHandsetTemplate",
  components: { Loader, CardDialog, FailedBreathRecord, PanelFooter },
  mixins: [DiagnosticNotificationListenerMixin, handsetLocking, productMixin],
  data(): {
    loading: boolean;
    notificationWatcher: UploadLifecycleWatcher | null;
  } {
    return {
      loading: true,
      notificationWatcher: null,
    };
  },
  computed: {
    requiresLocking(): boolean {
      return (
        this.$route.meta?.requiresLocking ??
        this.$route.matched.some((route) => route.meta.requiresLocking)
      );
    },
    hasBorder(): boolean {
      return !(
        this.$route.name === `${this.product}-results-history` ||
        this.$route.name === `${this.product}-handset`
      );
    },
    handsetRouteName(): `${Product}-${"handset-results" | "start-test"}` | "" {
      if (
        this.$route.matched.some(
          (route) => route.name === `${this.product}-results-history`
        )
      ) {
        return `${this.product}-handset-results`;
      }
      if (
        this.$route.matched.some(
          (route) => route.name === `${this.product}-handset`
        )
      ) {
        return `${this.product}-start-test`;
      }
      return "";
    },
    handsets(): HandsetV2[] | null {
      return this.$store.getters[
        ntsPrefix(GetterTypes.HANDSETS_SORTED_BY_SERIAL_NUMBER)
      ];
    },
    availableHandsets(): HandsetV2[] {
      // handset are null when not yet fetched
      if (this.handsets === null) return [];

      // Filter available handsets based on url route.
      // Prevents changing handset mid test.
      if (this.$route.name === `${this.product}-loading-test`) {
        const selectedHandset = this.handsets.find(
          (h) => h.udi === this.$route.params.udi
        );
        return selectedHandset ? [selectedHandset] : [];
      }

      return this.handsets;
    },
    validUdi(): boolean | undefined {
      // valid UDI if the UDI is missing or the handsets have not been fetched yet
      if (!this.$route.params.udi || this.handsets === null) return true;
      return this.handsets?.some((udi) => this.$route.params.udi === udi.udi);
    },
    lockedHandsets(): HandsetV2[] {
      if (!this.requiresLocking) return [];
      const allLockedHandsets = this.availableHandsets.filter(
        (handset) => handset.lockedBy !== null
      );
      const userSpecificLockedHandsets = allLockedHandsets.filter(
        (handset) => handset.lockedBy !== this.$store.state.userInfo?.username
      );
      return userSpecificLockedHandsets;
    },
  },
  created() {
    // explicit setting of loading - init to true
    this.loading = true;
    this.$store.dispatch(
      ntsPrefix(ActionTypes.REPORT_BREATH_RECORD_FAILED_STATUS),
      false
    );

    this.$store
      .dispatch(ntsPrefix(ActionTypes.GET_HANDSETS), this.product)
      // eslint-disable-next-line no-console
      .catch((error) => console.error(error))
      .finally(() => (this.loading = false));
  },
  destroyed() {
    this.notificationWatcher?.dispose();
  },
  beforeRouteUpdate(to, _, next) {
    const resultsHistory = `${this.product}-results-history`;
    const handsetResults = `${this.product}-handset-results`;
    const handset = `${this.product}-handset`;
    const startTest = `${this.product}-start-test`;
    if (this.handsets?.length !== 1) {
      next();
      return;
    }
    if (to.name === resultsHistory) {
      next({
        name: handsetResults,
        params: { udi: this.handsets[0].udi },
      });
    } else if (to.name === handset) {
      next({ name: startTest, params: { udi: this.handsets[0].udi } });
    }
    next();
  },
  methods: {
    closeFailureMessage() {
      this.$store.dispatch(
        ntsPrefix(ActionTypes.REPORT_BREATH_RECORD_FAILED_STATUS),
        false
      );
      const startTest = `${this.product}-start-test`;
      if (this.$route.name === startTest) return;
      this.$router.push({
        name: startTest,
        params: { udi: this.$route.params.udi },
      });
    },
  },
  watch: {
    validUdi: {
      handler(newValidUdi: boolean | undefined) {
        if (newValidUdi === false) {
          this.$store.commit(MUTATION_ADD_ERROR, {
            message: "Error 404. Cannot find the requested handset.",
          });
          this.$router.replace({ name: this.product });
        }
      },
      immediate: true,
    },
    handsets: {
      handler(newHandsets: HandsetV2[] | null) {
        if (newHandsets?.length === 1 && this.handsetRouteName) {
          this.$router.replace({
            name: this.handsetRouteName,
            params: { udi: newHandsets[0].udi },
          });
        }
      },
      immediate: true,
    },
    handsetRouteName: {
      handler(newRouteName: string) {
        if (newRouteName !== "") return;
        this.$store.commit(MUTATION_ADD_ERROR, {
          message: "Unexpected navigation error.",
        });
        this.$router.push({ name: this.product });
      },
      immediate: true,
    },
    "$route.params.udi": {
      handler(newUdi: string | undefined) {
        // method defined on the listener
        // @ts-ignore
        this.resetListener();
        this.notificationWatcher?.dispose();

        // if no handset is selected do nothing
        if (!newUdi) return;

        // if the route does not contain the handset route do nothing
        // this is true for the handset-results
        if (
          !this.$route.matched.some(
            (route) => route.name === `${this.product}-handset`
          )
        )
          return;

        this.notificationWatcher = new UploadLifecycleWatcher(
          this.$route.params.udi,
          (this.$store as Store<any>).pusher!
        );
      },
      immediate: true,
    },
  },
});
