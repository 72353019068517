var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('main',{staticClass:"select-handset"},[_c('div',{staticClass:"container page-container"},[(_vm.handsets === null || _vm.loading)?_c('Loader',{key:"loader",attrs:{"text":"Loading your data..."}}):_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"handset-sidebar",class:{ 'has-border': _vm.hasBorder }},[(_vm.availableHandsets.length === 1)?_c('h2',[_vm._v("YOUR N-TIDAL HANDSET")]):_c('h2',[_vm._v("SELECT YOUR HANDSET")]),_c('div',{staticClass:"handset-buttons"},_vm._l((_vm.availableHandsets),function(handset){return _c('router-link',{key:handset.udi,staticClass:"button is-secondary",class:{
              locked: _vm.lockedHandsets.some(
                (lockedHandset) => lockedHandset.udi === handset.udi
              ),
            },attrs:{"to":_vm.availableHandsets.length === 1
                ? ''
                : {
                    name: _vm.handsetRouteName,
                    params: { udi: handset.udi },
                  }}},[_c('h2',[_vm._v(_vm._s(handset.serialNumberStr))]),_c('p',[_vm._v("N-TIDAL HANDSET")])])}),1)]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"subview"},[(_vm.handsets !== null && !_vm.loading)?_c('transition',{attrs:{"name":"fly","mode":"out-in"}},[(_vm.validUdi)?_c('router-view',{key:_vm.$route.path,attrs:{"product":_vm.product}}):_vm._e()],1):_vm._e()],1),_c('PanelFooter')],1)])],1),(_vm.$store.state.nts.breathRecordFailed)?_c('div',{staticClass:"breath-error"},[_c('CardDialog',[_c('div',{staticClass:"center"},[_c('FailedBreathRecord',{staticClass:"error-message"}),_c('button',{staticClass:"button is-primary",on:{"click":function($event){return _vm.closeFailureMessage()}}},[_vm._v(" Back ")])],1)])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }