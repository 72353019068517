import Router from "vue-router";
import Vue from "vue";

import LogoutRedirect from "@/views/LogoutRedirect.vue";
import login from "@/router/login";
import product from "@/router/product";
import ntc from "@/router/product/ntc";
import nts from "@/router/product/nts";
import ntd from "@/router/product/ntd";
import ViewUITest from "@/views/ViewUITest.vue";
import ViewDashboard from "@/views/common/ViewDashboard.vue";
import NtsPrintPage from "@/views/nts/NtsPrintPage.vue";
import NtdPrintPage from "@/views/ntd/NtdPrintPage.vue";

Vue.use(Router);

//Router definition.

/**
 * Main application router. See [Vue Router docs](https://router.vuejs.org/) for more information. Includes routes from
 * local files as well as some small routes. These include: redirect to login, ui, logout, eula-consent, and print.
 *  Within each routes `meta`data, there are multiple options.
 *
 * If `requiresLogin` is true, and the user isn't logged in, they'll be redirected to the login page. Similarly, if
 * it's false and the user is logged in, they'll be redirected to the list view.
 *
 * The `order` property is required and used to ensure route transitions work correctly:
 * if two routes have a different order property, there will be a transition between them. See [[App.onRouteChange]]
 * for more details.
 *
 * The `title` function is required and used to generate page titles, it gets passed the currentRoute as
 * the first argument. Again, see [[App.onRouteChange]] for more details.
 *
 * The `selectedProduct` property indicates the product being used. This is either ntc, unknown, or nts
 *
 * The `requiresLocking` property indicates handset locking will occur
 */

// * vue-router 3 does not make it easy to namespace routes with
// prefixes (https://github.com/vuejs/vue-router/issues/2105)

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    { path: "/", redirect: { name: "login" } },
    {
      path: "/ui",
      name: "ui",
      component: ViewUITest,
      meta: {
        requiresLogin: true,
        order: 2,
        title: () => "UI Components",
      },
    },
    {
      path: "/logout",
      name: "logout",
      meta: { title: () => "Logout" },
      component: LogoutRedirect,
    },
    {
      path: "/eula-consent",
      name: "eulaConsent",
      component: ViewDashboard,
      meta: {
        requiresLogin: true,
        order: 1,
        title: () => "Consent to Eula",
      },
    },
    {
      path: "/nts/:capnogramId/print",
      name: "print-test-result-nts",
      component: NtsPrintPage,
      meta: {
        title: () => "Print Test",
        requiresNtsUiPermission: true,
        selectedProduct: "nts",
        requiresLogin: true,
      },
    },
    {
      path: "/ntd/:capnogramId/print",
      name: "print-test-result-ntd",
      component: NtdPrintPage,
      meta: {
        title: () => "Print Test",
        requiresNtdUiPermission: true,
        selectedProduct: "ntd",
        requiresLogin: true,
      },
    },
    login,
    product,
    ntc,
    nts,
    ntd,

    { path: "*", redirect: { name: "login" } },
  ],
});

export default router;
