
import { defineComponent } from "vue";
import Card from "@/components/Card.vue";
import TextInput from "@/components/TextInput.vue";
import { apiClient } from "@/store";
import { HttpResponseError } from "@/errors";
import { timerMixin } from "@/components/mixins/timerMixin";
import { ntsPrefix, ActionTypes } from "@/store/internal/modules/nts";

type ServerValidationResponse = {
  [key: string]: string[];
};

const flattenServerValidationResponse = (
  response: ServerValidationResponse | null
) => {
  if (!response) return "";

  return Object.values(response).flat().join(" ");
};

export default defineComponent({
  name: "SmokingHistory",
  components: { Card, TextInput },
  mixins: [timerMixin],
  data() {
    return {
      packYears: "",
      error: "",
      awaitingServerResponse: false,
    };
  },
  computed: {
    formattedPackYears() {
      if (this.packYears === "1") return "pack year";
      return "pack years";
    },
  },
  methods: {
    async handleSetPackYears() {
      this.awaitingServerResponse = true;
      // reset the input error
      this.error = "";

      const capnogramId = this.$route.params.capnogramId;
      const route = `/v2/capnograms/${capnogramId}/smokingHistory/`;

      const body =
        this.packYears === ""
          ? { isKnown: false, packYears: null }
          : { isKnown: true, packYears: this.packYears };

      const response = await apiClient.request({
        method: "POST",
        route,
        body,
        expectedErrorCodes: [400],
        sendJSON: true,
      });

      if (response.status === 400) {
        this.error =
          flattenServerValidationResponse(
            response.data as ServerValidationResponse
          ) || "Unexpected pack year value";
        this.awaitingServerResponse = false;
        return;
      }

      if (!response.ok) {
        this.awaitingServerResponse = false;
        throw new HttpResponseError(route, "POST", response.status);
      }

      // request triggers the server to queue async tasks which generate notifications
      // these notification handled in the listener and will navigate the user
    },
  },
  async created() {
    await this.$store.dispatch(ntsPrefix(ActionTypes.LOCK_HANDSET), {
      handsetUdi: this.$route.params.udi,
    });
  },
});
