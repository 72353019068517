
import { defineComponent, PropType } from "vue";
import IconInfo from "@/assets/icons/IconInfo.vue";
import { formatNtdResultsMixin } from "@/components/mixins/formatNtdResultsMixin";
import { EnrichedCapnogram } from "@/store/internal/modules/nts";

export default defineComponent({
  name: "NtdDiagnosis",
  components: {
    IconInfo,
  },
  mixins: [formatNtdResultsMixin],
  props: {
    capnogram: {
      type: Object as PropType<EnrichedCapnogram | null>,
      required: true,
    },
  },
  data() {
    return { showExplanation: false };
  },
});
